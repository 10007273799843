<template>
    <div class="anwCardDialog">
        <el-dialog :title="myTitle" width="600"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="anwCardForm" ref="anwCardDialogForm" :rules="anwCardRules">

                <el-form-item label="名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="anwCardForm.name" autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'anwCardDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                schoolList: [], professionList: [], yearList: [],
                anwCardForm:{ id: null, name: null, },
                anwCardRules: {
                    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            anwCard: {
                type: Object
            }
        },
        watch: {
            anwCard: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.anwCardForm = { id: n.id,  name: n.name, };
                        if(n.id == null) this.myTitle = '新建答题卡'; else this.myTitle = '编辑答题卡';
                    }else{
                        this.anwCardForm = { id: null, name: null,};
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){
                console.log(this.anwCardForm);
                let that = this;
                this.$refs.anwCardDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.anwCardForm.licenceId = this.userInfo.licenceId;
                        let param = { controllerName: 'exam/scantron', methodName: '/saveOrUpdate', param: this.anwCardForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('anwCard', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该答题卡已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .anwCardDialog{

    }


</style>


