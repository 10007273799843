<template>
    <div class="anwCardList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称"  @keyup.enter.native='loadAnwCardList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadAnwCardList'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='openAnwCard(null)'>添加</el-button>
                </el-form-item>

            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
              <el-table-column type="index" width="70" label="序号"></el-table-column>
                <el-table-column prop="id" label="ID"  align='center' width="70" ></el-table-column>
                <el-table-column prop="name" label="名称"  align='left' ></el-table-column>
                <el-table-column prop="operation" align='left' label="操作" width="500">
                    <template slot-scope='{row}'>
                        <el-button type='text' size="mini" @click='openAnwCardView(row)'>编辑</el-button>
                        <el-button type='text' size="mini" @click='copyCard(row)'>复制</el-button>
                        <el-button size="text" :disabled="!(row.html_url != null && row.html_url.endsWith('.pdf'))" @click='previewPdf(row)'>预览</el-button>
                        <el-button type='text' :disabled="!(row.html_url != null && row.html_url.endsWith('.pdf'))" size="mini" @click='downloadPdf(row)'>下载PDF</el-button>
                        <el-button type="danger" plain icon='edit' size="mini" @click='onDeleteAnwCard(row)'>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
        <anwCardDialog ref="anwCardDialog" :anwCard="selAnwCard" @ok="loadAnwCardList"></anwCardDialog>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import anwCardDialog from "./anwCardDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import { saveAs } from 'file-saver';

    export default {
        name: 'anwCardList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                schoolList: [], professionList: [],
                selAnwCard: null, selAnwCardDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},
            }
        },
        components:{
            anwCardDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadAnwCardList();
	   },
        methods: {

            loadAnwCardList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), state: 0,
                  createrId: this.userInfo.id, orderBy: 'id desc'};
                let param = { controllerName: 'exam/scantron', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadAnwCardList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadAnwCardList()
            },
            onDeleteAnwCard(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该答题卡, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    let param = { controllerName: 'exam/scantron', methodName: '/saveOrUpdate', param: { id: id, state: -1 }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadAnwCardList()
                        }

                    });
                }).catch(() => {

                });
            },

            openAnwCard(row){
                if(row != null){
                    this.selAnwCard = row;
                }else{
                    this.selAnwCard = { id: null,  name: null, time: (new Date()).getMilliseconds()};
                }
                this.$refs.anwCardDialog.showDialog();
            },
            openAnwCardView(item){
                let routeData = this.$router.resolve({name: "cardView", query: {id: item.id }});
                window.open(routeData.href, '_blank');
            },
            copyCard(row){
                let param = { controllerName: 'exam/scantron', methodName: '/copyCard', param: {id: row.id}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('anwCard', code);
                    if (code == 0) {
                        this.loadAnwCardList();
                    }else{
                        this.$message({message: '复制失败', type: 'error', duration: 5000});
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
            previewPdf(row){
                window.open(row.html_url, '_blank');
            },
            downloadPdf(row){
                let downName = row.name+".pdf";
                saveAs(row.html_url, downName);
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


